<script setup lang="ts">
import type { CampaignState } from "evangelist-common/enums";
import { CreatorappCampaignWeekUserEligibilityState } from "evangelist-db-schema/api";
import type { Brand } from "~/advertisorsData/shared/types";
import {
  CampaignEligibilityDtoStateEnum,
  type CampaignListItem,
  type SuggestedCampaignDto,
} from "~/common/Types";

const loading = ref(false);
interface Props {
  brand: Brand;
}

const props = defineProps<Props>();

const transformToCampaignListItem = (
  campaign: SuggestedCampaignDto,
): CampaignListItem => ({
  ...campaign,
  campaignUuid: campaign.id,
  campaignWeekId:
    "not used, if you see this in URL or API request, it is a bug",
  eligibilityState: transformCampaignEligibilityState(
    campaign.eligibility.state,
  ),
  campaignState: campaign.state as CampaignState,
  userTopN: Number(campaign.userTopN),
});

const transformCampaignEligibilityState = (
  eligibilityState: CampaignEligibilityDtoStateEnum,
): CreatorappCampaignWeekUserEligibilityState => {
  switch (eligibilityState) {
    case CampaignEligibilityDtoStateEnum.New:
    case CampaignEligibilityDtoStateEnum.EvaluationPending:
    case CampaignEligibilityDtoStateEnum.Evaluating:
      return CreatorappCampaignWeekUserEligibilityState.Pending;

    case CampaignEligibilityDtoStateEnum.Eligible:
    case CampaignEligibilityDtoStateEnum.EligibleBasic:
      return CreatorappCampaignWeekUserEligibilityState.Eligible;

    case CampaignEligibilityDtoStateEnum.NotEligibleBasic:
    case CampaignEligibilityDtoStateEnum.NotInvited:
    case CampaignEligibilityDtoStateEnum.NotEligible:
      return CreatorappCampaignWeekUserEligibilityState.Excluded;

    default: {
      const exhaustiveCheck: never = eligibilityState;
      throw new Error(`Unhandled eligibility state: ${exhaustiveCheck}`);
    }
  }
};

const signWithX = async () => {
  try {
    const twitterResponse = await apiService.getTwitterLoginUrl();
    const { redirectUrl } = twitterResponse.data;
    window.location.href = redirectUrl;
  } catch (error) {
    console.error(error);
  }
};

function getCustomBudgetText(campaign: SuggestedCampaignDto) {
  if (props.brand.id === "marinade") {
    return campaign.apyBonus > 0
      ? "Total Budget"
      : "Total Budget in 3 Campaigns";
  }

  if (props.brand.id === "switchboard") {
    return "Total Budget in 2 Campaigns";
  }

  if (props.brand.id === "metaplex") {
    return "Total Budget in 2 Campaigns";
  }

  return "Total Budget";
}
</script>

<template>
  <div
    :class="[brand.designSchema.darkMode ? 'text-white' : '']"
    class="container-800 mt-8 lg:mt-14 lg:text-center font-semibold"
  >
    <div class="text-4xl lg:text-6xl mb-8">
      Join the {{ brand.name }}<br />Ambassador Program
    </div>
    <div class="text-2xl mb-8 w-3/4 lg:w-full">
      Earn rewards for your posts on X.<br class="hidden lg:block" />
      Create your ambassador account on Evangelist today.
    </div>
    <SigninButton
      :style="{
        backgroundColor: brand.designSchema.primaryColor,
        borderRadius: brand.designSchema.borderRadius,
      }"
      class="btn transition-all duration-100 ease-out"
      :class="[
        brand.designSchema.darkMode
          ? 'text-black hover:!bg-white '
          : 'text-white hover:!bg-black',
      ]"
    >
      Check eligibility <IconsTweetIcon />
    </SigninButton>
  </div>

  <PageLoader v-if="loading" class="mt-14" />
  <div v-else>
    <div
      v-if="(brand.campaigns?.length ?? 0) > 0"
      class="container-800 my-10 lg:mt-14"
    >
      <div class="container-800 mt-8 lg:mt-14 lg:text-center font-semibold">
        <div
          class="text-2xl mb-8 w-3/4 lg:w-full"
          :class="[brand.designSchema.darkMode ? 'text-white' : '']"
        >
          Join these Ambassador Campaigns by {{ brand.name }}
        </div>
      </div>
    </div>

    <div class="container-950 mt-14 flex justify-center">
      <div class="flex flex-wrap items-center justify-between gap-6">
        <div class="flex flex-col gap-3 text-sm">
          <CampaignRow
            v-for="campaign in brand.campaigns"
            :key="campaign.id"
            class="cursor-pointer"
            :class="[brand.id === 'switchboard' ? 'bg-white rounded-2xl' : '']"
            :is-dark="brand.designSchema.darkMode"
            :campaign="transformToCampaignListItem(campaign)"
            :landingPageMode="true"
            :customTotalBudgetText="getCustomBudgetText(campaign)"
            @click="signWithX"
          />
        </div>
      </div>
    </div>

    <div class="container-950 mt-14">
      <div class="p-6 py-8 lg:p-12 bg-white rounded-2xl">
        <ContentsHowDoesItWorksSlider />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
